import React from "react";
import AppContext from "../AppContext";
import { Button, Card, Col, Form, ListGroup, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faFilePen, faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import moment from 'moment';

class LivroDespachoForm extends React.Component {

    static contextType = AppContext;

    state = {
        ...this.props.livro,
        Partes: this.props.livro?.Partes?.map(parte => ({ ...parte, despacho: "Ciente.", Despachos: [] })) ?? [],
        data_despacho: moment(this.props.livro?.data_despacho ?? undefined).format("YYYY-MM-DD")
    };

    submit(event) {
        event.preventDefault();

        const errors = [];
        let i = 0;

        const fields = {
            data_despacho: "Data de Despacho",
        };
        Object.keys(fields).forEach(key => !this.state[key] ? errors.push(<div key={i++}>{fields[key]} não pode ser vazio.</div>) : null);

        this.state.Partes.forEach((parte, j) => {
            const fields = {
                //despacho: "Despacho",
            };
            Object.keys(fields).forEach(key => !parte[key] ? errors.push(<div key={i++}>{fields[key]} da Parte {j + 1} não pode ser vazio.</div>) : null);
        })

        if (errors.length) {
            this.context.addToast({ header: "Erro de Validação", body: errors });
            return;
        }

        this.context.request("PUT", `/livro/${this.props.livro?.id}`, {
            data_despacho: this.state.data_registro,
            Partes: this.state.Partes
        }).then(() => {
            this.context.addToast({
                header: "Sucesso",
                body: "Alterações gravadas com sucesso!"
            });
            this.props.onSave();
        }).catch(error => {
            console.log(error);
            this.context.addToast({
                header: "Falha",
                body: "Houve uma falha na gravação dos dados: " + error.message
            });
        });

    }

    downloadBase64File(anexo) {
        const link = document.createElement('a');
        link.href = anexo.arquivo;
        link.download = anexo.nome;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    async downloadAnexo(anexo) {
        if (!anexo.arquivo) {
            // Caso o arquivo seja indefinido, faz o download
            try {
                const { arquivo } = await this.context.request("GET", `/anexo/${anexo.id}`);
                anexo.arquivo = arquivo;
                this.setState(state => ({
                    Anexos: state.Anexos.map(a => a === anexo ? {
                        ...anexo
                    } : a)
                }), () => {
                    this.downloadBase64File(anexo)
                })
            } catch (error) {
                console.error('Erro ao baixar o arquivo:', error);
            }
        } else {
            this.downloadBase64File(anexo);
        }
    }

    render() {

        const {
            datahora_inicio,
            datahora_termino,
            data_despacho,
            Usuario,
            Despachante,
            Categoria,
            Partes,
        } = this.state

        return <Form onSubmit={event => this.submit(event)} className="form-large">
            <h4 className="d-flex align-items-center">
                <FontAwesomeIcon icon={faFilePen} className="me-2" />Despacho de Livro
                <div className="ms-auto text-nowrap">
                    <Button className="me-2" variant="secondary" onClick={() => this.props.onCancel()}>
                        <FontAwesomeIcon icon={faTimes} /><span className="d-none d-md-inline">&nbsp;Cancelar</span>
                    </Button>
                    <Button type="submit" variant="primary">
                        <FontAwesomeIcon icon={faSave} /><span className="d-none d-md-inline">&nbsp;Gravar Alterações</span>
                    </Button>
                </div>
            </h4>
            <hr />
            <h4>Dados Gerais</h4>
            <Row className="mt-3">
                <Col md={4}>
                    <Form.Group className="mb-2">
                        <Form.Label>Nº do Livro</Form.Label>
                        <Form.Control value={this.state.numero?.toString().padStart(6, '0')} disabled />
                    </Form.Group>
                </Col>
                <Col md={8}>
                    <Form.Group className="mb-2">
                        <Form.Label>Responsável pelo Despacho</Form.Label>
                        <Form.Control value={Despachante?.nome ?? this.context.usuario.nome} disabled />
                    </Form.Group>
                </Col>
                <Col md={12}>
                    <Form.Group className="mb-2">
                        <Form.Label>Responsável pelo Registro</Form.Label>
                        <Form.Control value={Usuario.nome} disabled />
                    </Form.Group>
                </Col>
                <Col md={3}>
                    <Form.Group className="mb-2">
                        <Form.Label>Unidade</Form.Label>
                        <Form.Control value={Categoria.nome} disabled />
                    </Form.Group>
                </Col>
                <Col md={3}>
                    <Form.Group className="mb-2">
                        <Form.Label>Data de Inicio</Form.Label>
                        <Form.Control type="datetime-local" value={moment(datahora_inicio).format("YYYY-MM-DDTHH:mm")} disabled />
                    </Form.Group>
                </Col>
                <Col md={3}>
                    <Form.Group className="mb-2">
                        <Form.Label>Data de Termino</Form.Label>
                        <Form.Control type="datetime-local" value={moment(datahora_termino).format("YYYY-MM-DDTHH:mm")} disabled />
                    </Form.Group>
                </Col>
                <Col md={3}>
                    <Form.Group className="mb-2">
                        <Form.Label>Data de Despacho</Form.Label>
                        <Form.Control type="date" value={data_despacho} onChange={e => this.setState({ data_despacho: e.target.value })} />
                    </Form.Group>
                </Col>
            </Row>
            <h4 className="mt-3">Participação</h4>
            {Partes.length ? Partes.map((parte, key) =>
                <Card key={key} className="mb-3">
                    <Card.Header><h5>{key + 1}. {parte.titulo}</h5></Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md={12}>
                                <Form.Label>Texto</Form.Label>
                                <p className="mb-3">
                                    {parte.texto}
                                </p>
                            </Col>
                            <Col md={12}>
                                <Form.Group className="mb-2">
                                    <Form.Label>Despacho</Form.Label>
                                    <Form.Control as="textarea" value={parte.despacho} onChange={e => this.setState(state => ({ Partes: state.Partes.map(p => p === parte ? { ...parte, despacho: e.target.value } : p) }))} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>)
                : <h5 className="mt-3 p-5 text-center" style={{ border: "black dashed 2px" }}>Sem alterações</h5>}
            <h4 className="mt-3">Anexos</h4>
            <ListGroup>
                {this.state.Anexos.map((anexo, key) =>
                    <ListGroup.Item key={key} className="d-flex justify-content-between align-items-center">
                        {anexo.nome}
                        <Button size="sm" onClick={() => this.downloadAnexo(anexo)}>
                            <FontAwesomeIcon icon={faDownload} />
                        </Button>
                    </ListGroup.Item>
                )}
            </ListGroup>
            <div className="mt-3 d-flex flex-row justify-content-end">
                <Button className="me-2" variant="secondary" onClick={() => this.props.onCancel()}>
                    <FontAwesomeIcon icon={faTimes} /><span className="d-none d-md-inline">&nbsp;Cancelar</span>
                </Button>
                <Button type="submit" variant="success">
                    <FontAwesomeIcon icon={faSave} /><span className="d-none d-md-inline">&nbsp;Gravar Alterações</span>
                </Button>
            </div>
        </Form >
    }
}

export default LivroDespachoForm;