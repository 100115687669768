import React from "react";
import AppContext from "../AppContext";
import { Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";

class CategoriaForm extends React.Component {

    static contextType = AppContext;

    state = {
        ...(this.props.categoria ?? {})
    };

    submit(event) {
        event.preventDefault();

        const id = this.state.id ?? '';

        const method = id ? "PUT" : "POST";
        const url = `/categoria/${id}`

        this.context.request(method, url, this.state)
            .then(() => {
                this.context.addToast({
                    header: "Sucesso",
                    body: "Alterações gravadas com sucesso!"
                });
                this.props.onSave();
            });

    }

    render() {

        const { nome, fiscal} = this.state;

        return <Form onSubmit={event => this.submit(event)}>
            <Form.Group className="mt-2">
                <Form.Label>Nome</Form.Label>
                <Form.Control required value={nome} onChange={e => this.setState({ nome: e.target.value })} />
            </Form.Group>
            <Form.Group className="mt-2">
                <Form.Label>Fiscal?</Form.Label>
                <Form.Select required value={fiscal !== ""? (fiscal? "1" : "0") : ""} onChange={e => this.setState({ fiscal: e.target.value })}>
			<option></option>
			<option value="1">Sim</option>
			<option value="0">N&atilde;o</option>
		</Form.Select>
            </Form.Group>
            <Form.Group className="mt-2 d-flex justify-content-between">
                <Button variant="secondary" onClick={() => this.context.closeModal()}>
                    <FontAwesomeIcon icon={faTimes} /> Cancelar
                </Button>
                <Button type="submit" variant="primary">
                    <FontAwesomeIcon icon={faSave} /> Gravar
                </Button>
            </Form.Group>
        </Form>
    }
}

export default CategoriaForm;